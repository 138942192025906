import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Box, Button, Text, Flex, Heading, useBreakpointValue,
} from '@chakra-ui/react';

import { FluidObject } from 'gatsby-image';
import Layout from '../components/layout';
import { getLinkCategoriesOnly } from '../util/categories';
import useNavItems from '../util/use-nav-items';

type Props = {
  data: {
    markdownRemark: {
      frontmatter: {
        text: string;
        image: {
          childImageSharp: {
            fluid: FluidObject
          }
      }}
    }
  }
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const navItems = useNavItems();
  const { text, image } = data.markdownRemark.frontmatter;
  const headingSize = useBreakpointValue({ base: '2xl', md: '3xl', lg: '4xl' });
  const linkSize = useBreakpointValue({ base: 'md', md: 'lg' });
  return (
    <Layout
      constrainWidth={false}
    >
      <Flex
        flexDirection="column"
        height="100%"
        color="white"
        position="relative"
        backgroundImage={image.childImageSharp.fluid.src}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        p={{ base: 0, md: 16, lg: 24 }}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'auto',
          backdropBrightness: 0.3,

        }}
      >
        <Box position="relative">

          <Heading
            size={headingSize}
            color="white"
            p={8}
            rounded="3xl"
          >

            <Text>{text}</Text>
          </Heading>
          <Box p={{ base: 8, md: 12 }}>
            {getLinkCategoriesOnly(navItems).map((category) => (
              <Button
                as={Link}
                key={category.href}
                to={category.href}
                rounded="full"
                mr={{ base: 4, md: 8 }}
                mb={{ base: 4, md: 8 }}
                px={{ base: 8, md: 12 }}
                colorScheme="pink"
                size={linkSize}
              >
                {category.label}
              </Button>
            ))}
          </Box>
        </Box>

      </Flex>
    </Layout>
  );
};

export const homeQuery = graphql`
  query HomeQuery {
  markdownRemark(fields: {itemType: {eq: "pages"}, slug: {regex: "/home/"}}) {
    html
    frontmatter {
      text
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    fields {
      slug
    }
  }
}

`;
export default IndexPage;
